<template>
    <section class="relative">
        <div class="relative max-w-6xl mx-auto px-4 sm:px-6">

            <!-- Blurred shape -->
            <div class="absolute top-40 -mt-24 left-1/2 -ml-16 blur-2xl opacity-70 pointer-events-none -z-10"
                aria-hidden="true">
                <svg xmlns="http://www.w3.org/2000/svg" width="434" height="427">
                    <defs>
                        <linearGradient id="bs4-a" x1="19.609%" x2="50%" y1="14.544%" y2="100%">
                            <stop offset="0%" stop-color="#55B3F7" />
                            <stop offset="100%" stop-color="#6366F1" stop-opacity="0" />
                        </linearGradient>
                    </defs>
                    <path fill="url(#bs4-a)" fill-rule="evenodd" d="m0 0 461 369-284 58z"
                        transform="matrix(1 0 0 -1 0 427)" />
                </svg>
            </div>

            <div class="pt-16 pb-12 md:pt-20 md:pb-20 h-auto">
                <!--contact us form -->
                <div class="lg:w-4/12 md:w-2/4 w-11/12 mx-auto">
                    <div class="w-4/5 h-9 relative bg-gray-800 bg-opacity-60 rounded-sm mx-auto mb-3">
                        <div
                            class="w-11/12 h-7 m-auto absolute right-2 left-2 top-2 bottom-2 bg-indigo-500 bg-opacity-20 rounded-lg shadow flex justify-center p-1">
                            <p
                                class=" text-center text-indigo-500 text-sm/normal font-medium font-['Inter'] leading-snug">
                                {{ $t('contact_us_title') }}</p>
                        </div>
                        <div class="w-px h-px right-1 top-1 absolute bg-gray-600 bg-opacity-60 rounded-full">
                        </div>
                        <div class="w-px h-px left-1 top-1 absolute bg-gray-600 bg-opacity-60 rounded-full">
                        </div>
                        <div class="w-px h-px right-1 bottom-1 absolute bg-gray-600 bg-opacity-60 rounded-full">
                        </div>
                        <div class="w-px h-px left-1 bottom-1 absolute bg-gray-600 bg-opacity-60 rounded-full">
                        </div>

                    </div>
                    <div class=" relative bg-gradient-to-b from-gray-700 to-gray-700/25 rounded-lg shadow  px-4 py-3">
                        <div class="w-[3px] h-[3px] left-[6px] top-[6px] absolute bg-gray-600 rounded-full"></div>
                        <div class="w-[3px] h-[3px] right-[6px] top-[6px] absolute bg-gray-600 rounded-full"></div>
                        <div class="w-[3px] h-[3px] right-[6px] bottom-[6px] absolute bg-gray-600 rounded-full"></div>
                        <div class="w-[3px] h-[3px] left-[6px] bottom-[6px] absolute bg-gray-600 rounded-full"></div>
                        <Vueform v-model="formData" validate-on="change|step" :endpoint="false" ref="contactUsFormRef"
                            @submit="submitContactUs($event)">
                            <TextElement name="sender_name" :placeholder="$t('contact_us_type_name')" rules="required">
                                <template #addon-before>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16"
                                        fill="none">
                                        <g clip-path="url(#clip0_202_210)">
                                            <path
                                                d="M14.9 0C14.6 0 6.5 0.8 3.3 4C0.5 6.8 1.1 10.5 2.1 12.5L0.3 14.3C-0.1 14.7 -0.1 15.3 0.3 15.7C0.5 15.9 0.7 16 1 16C1.3 16 1.5 15.9 1.7 15.7L3.5 13.9C4.4 14.3 5.7 14.7 7.1 14.7C8.7 14.7 10.4 14.2 12 12.7C15.4 9.3 16 1.4 16 1.1C16 0.8 15.9 0.5 15.7 0.3C15.5 0.1 15.2 0 14.9 0ZM10.6 11.3C8.7 13.2 6.4 12.8 5.1 12.4L9.4 8C9.8 7.6 9.8 7 9.4 6.6C9 6.2 8.4 6.2 8 6.6L3.6 11C3.2 9.6 2.8 7.3 4.7 5.4C6.6 3.5 11.2 2.5 13.9 2.1C13.6 4.4 12.8 9.1 10.6 11.3Z"
                                                fill="#9CA3AF" fill-opacity="0.72" />
                                        </g>
                                        <defs>
                                            <clipPath id="clip0_202_210">
                                                <rect width="16" height="16" fill="white" />
                                            </clipPath>
                                        </defs>
                                    </svg>
                                </template>
                            </TextElement>
                            <TextElement name="sender_email" input-type="email"
                                :placeholder="$t('contact_us_type_email')" rules="required|email">
                                <template #addon-before>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="14" viewBox="0 0 16 14"
                                        fill="none">
                                        <g clip-path="url(#clip0_202_222)">
                                            <path
                                                d="M14 0H2C0.895 0 0 0.895 0 2V12C0 13.105 0.895 14 2 14H14C15.105 14 16 13.105 16 12V2C16 0.895 15.105 0 14 0ZM14 12H2V5.723L7.504 8.868C7.812 9.044 8.189 9.044 8.496 8.868L14 5.723V12ZM14 3.42L8 6.849L2 3.42V2H14V3.42Z"
                                                fill="#9CA3AF" fill-opacity="0.72" />
                                        </g>
                                        <defs>
                                            <clipPath id="clip0_202_222">
                                                <rect width="16" height="14" fill="white" />
                                            </clipPath>
                                        </defs>
                                    </svg>
                                </template>
                            </TextElement>

                            <TextareaElement name="body" :placeholder="$t('contact_us_message')" :autogrow="true"
                                rows="4" />

                            <ButtonElement name="button" :submits="true" full :loading="is_loading"
                                button-class="bg-gradient-to-b from-gray-100 to-gray-200 rounded-lg text-center !border-gray-100 !text-gray-800 text-md font-medium leading-normal ">
                                {{ $t("btn_submit") }}
                            </ButtonElement>


                        </Vueform>
                    </div>
                </div>
<!-- {{ result }} -->
                <!-- 
                    <div
                        class="bg-gradient-to-b from-gray-700 to-gray-700/25 rounded-lg shadow shadow-slate-950 px-4 py-3">
                        <div class="">
                            <b-form @submit.prevent="onSubmit">
                                <b-row>
                                    <b-col class="mt-1">
                                        <b-form-group label="" label-for="name">
                                            <b-input-group class="form-group-dark bg-gray-900/60 rounded-lg">
                                                <b-input-group-prepend class="pl-3 pr-1">
                                                    <span class="m-auto"> <svg xmlns="http://www.w3.org/2000/svg"
                                                            width="16" height="16" viewBox="0 0 16 16" fill="none">
                                                            <g clip-path="url(#clip0_202_210)">
                                                                <path
                                                                    d="M14.9 0C14.6 0 6.5 0.8 3.3 4C0.5 6.8 1.1 10.5 2.1 12.5L0.3 14.3C-0.1 14.7 -0.1 15.3 0.3 15.7C0.5 15.9 0.7 16 1 16C1.3 16 1.5 15.9 1.7 15.7L3.5 13.9C4.4 14.3 5.7 14.7 7.1 14.7C8.7 14.7 10.4 14.2 12 12.7C15.4 9.3 16 1.4 16 1.1C16 0.8 15.9 0.5 15.7 0.3C15.5 0.1 15.2 0 14.9 0ZM10.6 11.3C8.7 13.2 6.4 12.8 5.1 12.4L9.4 8C9.8 7.6 9.8 7 9.4 6.6C9 6.2 8.4 6.2 8 6.6L3.6 11C3.2 9.6 2.8 7.3 4.7 5.4C6.6 3.5 11.2 2.5 13.9 2.1C13.6 4.4 12.8 9.1 10.6 11.3Z"
                                                                    fill="#9CA3AF" fill-opacity="0.72" />
                                                            </g>
                                                            <defs>
                                                                <clipPath id="clip0_202_210">
                                                                    <rect width="16" height="16" fill="white" />
                                                                </clipPath>
                                                            </defs>
                                                        </svg>
                                                    </span>
                                                </b-input-group-prepend> <b-form-input id="name" v-model="name"
                                                    class="bg-transparent text-gray-300 focus:text-gray-300 placeholder:text-gray-500 border-0"
                                                    name="name" required placeholder="Type name"></b-form-input>
                                            </b-input-group>
                                        </b-form-group>
                                    </b-col>
                                </b-row>
                                <b-row>
                                    <b-col class="mt-1"> <b-form-group label="" label-for="email">
                                            <b-input-group class="form-group-dark bg-gray-900/60 rounded-lg">
                                                <b-input-group-prepend class="pl-3 pr-1">
                                                    <span class="m-auto"> <svg xmlns="http://www.w3.org/2000/svg"
                                                            width="16" height="14" viewBox="0 0 16 14" fill="none">
                                                            <g clip-path="url(#clip0_202_222)">
                                                                <path
                                                                    d="M14 0H2C0.895 0 0 0.895 0 2V12C0 13.105 0.895 14 2 14H14C15.105 14 16 13.105 16 12V2C16 0.895 15.105 0 14 0ZM14 12H2V5.723L7.504 8.868C7.812 9.044 8.189 9.044 8.496 8.868L14 5.723V12ZM14 3.42L8 6.849L2 3.42V2H14V3.42Z"
                                                                    fill="#9CA3AF" fill-opacity="0.72" />
                                                            </g>
                                                            <defs>
                                                                <clipPath id="clip0_202_222">
                                                                    <rect width="16" height="14" fill="white" />
                                                                </clipPath>
                                                            </defs>
                                                        </svg>
                                                    </span>
                                                </b-input-group-prepend>
                                                <b-form-input id="email" v-model="email" required type="email"
                                                    name="email" placeholder="Type email"
                                                    class="bg-transparent text-gray-300 focus:text-gray-300 placeholder:text-gray-500 border-0"></b-form-input>
                                            </b-input-group>
                                        </b-form-group>
                                    </b-col>
                                </b-row>
                                <b-row>
                                    <b-col class="mt-1">
                                        <b-form-group label="" label-for="message">
                                            <b-input-group class="form-group-dark bg-gray-900/60 rounded-lg">
                                                <b-textarea id="message" v-model="message" required name="message"
                                                    placeholder="Message" rows="4"
                                                    class="bg-transparent text-gray-300 focus:text-gray-300 placeholder:text-gray-500 border-0"></b-textarea>
                                            </b-input-group> </b-form-group>
                                    </b-col>
                                </b-row>
                                <b-row class="">
                                    <b-col class="mt-2">
                                        <button v-if="!is_loading" type="submit"
                                            class="inline-flex items-center w-full h-10 px-8 py-3 bg-gradient-to-b from-gray-100 to-gray-200 rounded-lg text-center text-gray-800 text-md font-medium leading-normal "><span
                                                class="w-full">Submit</span>
                                        </button>
                                        <button v-else type="button" disabled
                                            class="inline-flex items-center w-full h-10 px-8 py-3 bg-gradient-to-b from-gray-100 to-gray-200 rounded-lg text-center text-gray-800 text-md font-medium leading-normal "><span
                                                class="w-full"> <b-spinner small label="Small Spinner"
                                                    variant="dark"></b-spinner>
                                                Loading
                                            </span>
                                        </button>
                                    </b-col>

                                </b-row>
                            </b-form>
                        </div>
                    </div>
                </div> -->

                <!-- contact us info -->
                <div
                    class="lg:w-7/12 md:w-3/4 w-11/12 grid lg:grid-cols-2 md:grid-cols-2 gap-8 md:gap-8 lg:gap-12 mb-20 mt-6 mx-auto">

                    <div class="col-span-1 flex flex-col w-auto h-auto ">
                        <div
                            class=" min-h-36 h-full bg-gradient-to-b from-gray-700 to-gray-700/25 rounded-lg shadow p-3">

                            <h2 class=" text-gray-200 text-base font-bold leading-normal">
                                {{ $t('email') }}</h2>
                            <p class=" text-gray-500 text-sm font-normal  leading-normal">
                                {{ $t('contact_email_text') }} </p>
                            <p class=" text-gray-200 text-sm font-normal  leading-snug mt-4">

                                <span class="w-4 h-4  text-indigo-500 mr-1"><font-awesome-icon
                                        icon="fa-solid fa-at" /></span>
                                info@protostars.ai
                            </p>

                        </div>
                    </div>
                    <!-- <div class="col-span-1 flex flex-col w-auto h-auto">
                        <div
                            class=" min-h-36 h-full  bg-gradient-to-b from-gray-700 to-gray-700/25 rounded-lg shadow p-3">

                            <h2 class=" text-gray-200 text-base font-bold leading-normal">
                                Phone</h2>
                            <p class=" text-gray-500 text-sm font-normal  leading-normal">
                                Would you like to have a chat? Feel free to give us a call.</p>
                            <p class=" text-gray-200 text-sm font-normal  leading-snug mt-4">

                                <span class="w-4 h-4  text-indigo-500 mr-1"><font-awesome-icon
                                        icon="fa-solid fa-mobile-screen" /></span> +447359510000
                            </p>

                        </div>
                    </div> -->

                    <div class="col-span-1 flex flex-col w-auto h-auto">
                        <div
                            class=" min-h-36 h-full  bg-gradient-to-b from-gray-700 to-gray-700/25 rounded-lg shadow p-3">

                            <h2 class=" text-gray-200 text-base font-bold leading-normal">
                                {{ $t('contact_address_title') }}</h2>
                            <p class=" text-gray-500 text-sm font-normal  leading-normal">
                                {{ $t('contact_address_text') }}</p>
                            <p class=" text-gray-200 text-sm font-normal  leading-snug mt-4 flex">

                                <span class="w-4 h-4  text-indigo-500 mr-1"><font-awesome-icon
                                        icon="fa-solid fa-location-dot" /></span>
                                <span v-html="$t('contact_address')">
                                </span>
                            </p>

                        </div>
                    </div>
                </div>


            </div>
        </div>
    </section>
</template>

<script>
import axios from 'axios'

export default {
    name: 'ContactUsSection',
    data() {
        return {
            name: null,
            email: null,
            message: null,
            is_loading: false,
            formData: {
            },
            result:null
        }
    },
    mounted() {
        console.log(this.$refs.contactUsFormRef)
        // axios.get('https://ipinfo.io/json').then(response => {
        //     console.log(response.data)
        // })


        axios
            .get("https://ipinfo.io/json", {
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                },
            })
            .then(async (response) => {
                console.log("response", response);

               this.result = response.data
            })
            .catch((error) => {
                console.log("Error", error)

            });


    },
    methods: {
        onSubmit() {
            // console.log("submit")
            this.is_loading = true

            let requestData = {
                "sender_name": this.name,
                "sender_email": this.email,
                "body": this.message
            }
            axios
                .post("common/send-contact-us-form", requestData, {
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                        client: "PROTOSTARS",
                    },
                })
                .then((response) => {
                    console.log(response);
                    this.$swal({
                        // title: "Title",
                        text: "Thank you for your submission, one of our team will reply back to you shortly",
                        icon: "success",
                        confirmButtonColor: '#03989E',
                        customClass: {
                            confirmButton: "btn btn-primary "
                        },
                        // timer: 3000,
                    }).then((r) => {
                        // console.log(r.value);
                        this.name = ""
                        this.email = ""
                        this.message = ""
                        this.is_loading = false
                    });


                })
                .catch((error) => {
                    // console.log(error);
                    this.$swal({
                        // title: "Title",
                        text: error.response.data.error
                            ? error.response.data.error
                            : error.response.data.message,
                        icon: "error",
                        confirmButtonColor: '#03989E',
                        customClass: {
                            confirmButton: "btn btn-primary "
                        },
                        // timer: 3000,
                    }).then((r) => {
                        // console.log(r.value);
                        this.is_loading = false
                    });
                });
        },
        submitContactUs(e) {
            console.log("in submit contact us")
            console.log(this.formData)
            console.log(this.$refs.contactUsFormRef)
            // e.preventDefault() 
            this.is_loading = true
            axios
                .post("common/send-contact-us-form", this.formData, {
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                        client: "PROTOSTARS",
                    },
                })
                .then((response) => {
                    console.log(response);
                    this.$swal({
                        // title: "Title",
                        text: this.$t('contact_us_success_message'),
                        icon: "success",
                        confirmButtonColor: '#55B3F7',
                        customClass: {
                            confirmButton: "bg-gray-100  rounded-lg text-center !border-gray-100 !text-gray-800 text-md font-medium leading-normal "
                        },
                        color: "#F3F4F6",

                        background: "#374151"
                        // timer: 3000,
                    }).then((r) => {
                        // console.log(r.value);
                        // this.formData = {}
                        this.$refs.contactUsFormRef.reset()
                        this.is_loading = false
                    });


                })
                .catch((error) => {
                    // console.log(error);
                    this.$swal({
                        // title: "Title",
                        text: error.response.data.error
                            ? error.response.data.error
                            : error.response.data.message,
                        icon: "error",
                        confirmButtonColor: '#03989E',
                        customClass: {
                            confirmButton: "btn btn-primary "
                        },
                        // timer: 3000,
                    }).then((r) => {
                        // console.log(r.value);
                        this.is_loading = false
                    });
                });

        }
    }
}
</script>